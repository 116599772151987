import { template as template_0e3379e7b1bc473a9443f67c989e1a86 } from "@ember/template-compiler";
const FKLabel = template_0e3379e7b1bc473a9443f67c989e1a86(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
