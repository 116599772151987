import { template as template_1d0527b8ef6241fbb2ee32e3264c5003 } from "@ember/template-compiler";
const FKText = template_1d0527b8ef6241fbb2ee32e3264c5003(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
