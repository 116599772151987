import { template as template_74a79345b82c4a4e83a3fc1cf197de69 } from "@ember/template-compiler";
const WelcomeHeader = template_74a79345b82c4a4e83a3fc1cf197de69(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
